:root {
  --surface: #15192c; /*dark: black100 */
  --white: #15192c; /*dark: black100 */

  --black100: #ffffff; /*dark: white */
  --black90: #b7bac7; /*dark: black10 */
  --black80: #a3a8b8; /*dark: black20 */
  --black60: #80869d; /*dark: black30 */
  --black40: #686f8c; /*dark: black40 */
  --black30: #515873; /*dark: black50 */
  --black20: #444b66; /*dark: black60 */
  --black10: #373d57; /*dark: black70 */
  --black5: #2f2e40; /*dark: black80 */
  --black2: #22273c; /*dark: black90 */

  --error: #e93c52;
  --warning: #e65600;
  --success: #32c587;

  --primary: #5229c5;
  --secondary: #20a4f3;
}

.login-page {
  height: 100vh;
  width: 100%;
  background-color: var(--surface);
  color: var(--black100);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
  }

  &__box {
    text-align: center;
    width: 340px;
    background-color: var(--black2);
    border-radius: var(--small-border-radius);
    margin: 1rem;
    padding: 1rem;
    border: 1px solid var(--black10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;

    h2 {
      font-size: 18px;
    }
    img {
      width: 48px;
    }

    &__message {
      color: var(--black80);
      font-size: 14px;
      line-height: 22px;
    }

    button,
    .MuiButton-contained {
      box-sizing: border-box;
      background-color: var(--primary);
      width: 300px;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      margin-top: auto;
      &:hover,
      &:active,
      &:focus {
        background-color: var(--primary);
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    label {
      span {
        display: block;
        text-align: left;
        font-size: 12px;
        color: var(--black60);
      }
    }

    input {
      box-sizing: border-box;
      background-color: var(--white);
      border: 1px solid var(--black10);
      padding: 0.5rem;
      margin-bottom: 1rem;
      outline: none;
      border-radius: var(--small-border-radius);
      width: 300px;
      color: var(--black100);
      &:hover,
      &:active,
      &:focus {
        border: 1px solid var(--black100);
      }
    }
  }

  &__read-more {
    color: var(--black80);
    a {
      color: var(--black100);
      text-decoration: none;
    }
  }
}
